/* src/components/Countdown/Countdown.css */
.countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-family: Arial, sans-serif;
    color: white;
    z-index: 2;
    height: 100vh; /* Full viewport height to center vertically */
}

.time-unit {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.time-value {
    font-size: 3rem;
    font-weight: bold;
}

.time-label {
    font-size: 1rem;
    margin-top: 0.2rem;
    text-transform: uppercase;
    opacity: 0.7;
}