/* src/components/PasswordInput/PasswordInput.css */
.password-input-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 2;
    padding: auto;
}

.password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(50, 50, 50, 0.9);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    max-width: 600px; /* Limit width for large screens */
    width: 100%; /* Full width on mobile */
    box-sizing: border-box; /* Include padding in width calculation */
}

.password-form label {
    font-size: 16px;
    margin-bottom: 10px;
    color: #ccc;
    align-items: center;
}

.password-input-group {
    display: flex;
    align-items: center;
    gap: 5px;
}

.password-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    margin-bottom: 10px;
    text-align: center;
    outline: none;
    box-sizing: border-box;
}

.password-delimiter {
    color: #fff;
    font-size: 18px;
    margin-bottom: 12px;
    font-weight: bold;
}

.eye-button {
    margin-left: 10px;
    margin-bottom: 10px;
    background: none;
    border: none;
    color: #ccc;
    cursor: pointer;
    font-size: 18px;
}

.eye-button:hover {
    color: #fff;
}

.password-form button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 12px;
    font-size: 16px;
    color: white;
    background-color: #333;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
    margin-top: 15px;
}

.password-form button:hover {
    background-color: #555;
}


@media (max-width: 768px) {
    .password-form {
        padding: 15px;
    }

    .password-form label {
        font-size: 14px;
    }

    .password-form input {
        font-size: 14px;
        padding: 8px;
    }

    .password-form button {
        padding: 8px 10px;
        font-size: 14px;
        max-width: 100%; /* Ensure full width on smaller screens */
    }

    .password-form button::before {
        font-size: 18px; /* Adjust emoji size for smaller screens */
    }
}