/* src/components/BackgroundAnimation/BackgroundAnimation.css */
.background-animation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black; /* Complete dark background */
    overflow: hidden;
    z-index: -1;
}

.christmas-tree {
    opacity: 0.8; /* Slight transparency for a softer look */
    animation: fade-in-out 5s ease-in-out;
}

@keyframes fade-in-out {
    0% { opacity: 0; }
    20% { opacity: 0.8; }
    80% { opacity: 0.8; }
    100% { opacity: 0; }
}