/* src/styles/App.css */
.toggle-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 8px 12px;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.toggle-button:hover {
  opacity: 1;
}