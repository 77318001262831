.pdf-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.header {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: center; /* Center align both elements */
}

select {
    flex: 1; /* Make select take the remaining space */
    max-width: 90%; /* Prevent overflow */
    margin-bottom: 10px;
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f8f9fa;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2710%27 height=%275%27 viewBox=%270 0 10 5%27%3E%3Cpath fill=%27%23333%27 d=%27M0 0l5 5 5-5z%27/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px 12px;
}

/* Hover effect */
select:hover {
    border-color: #98FF98;
    box-shadow: 0 2px 8px rgba(13, 91, 38, 0.5);
}

/* Focus effect */
select:focus {
    outline: none;
    border-color: #98FF98;
    box-shadow: 0 2px 8px rgba(13, 91, 38, 0.5);
    background-color: #ffffff;
}

/* Hamburger menu styling */
/* Hamburger menu styling with gradient */
.hamburger-menu {
    font-size: 16px;
    color: white;
    background: linear-gradient(45deg, #98FF98, #76E276); /* Gradient background */
    border: none;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: background 0.3s, transform 0.3s; /* Smooth transitions */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: auto;
}

.menu {
    position: absolute;
    top: 50px;
    left: 10px;
    padding: 10px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 20;
}

.menu select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
}

.menu ul {
    list-style: none;
    padding: 0;
  }

  .menu li button {
    background: none;
    border: none;
    color: #76e276;
    cursor: pointer;
    text-align: left;
    margin: 5px 0;
  }

  .menu li button:hover {
    text-decoration: underline;
  }

/* Style the favorites list within the menu */
.favorites-list h3 {
    font-size: 18px;
    margin: 0 0 5px;
}

/* Star button styling */
.favorite-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 24px;
    color: #ccc;
    background: none;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);;
    border: none;
    cursor: pointer;
    margin: auto;
    transition: color 0.3s, transform 0.3s;
}

.favorite-btn.favorited {
    color: #e8ff42; /* Highlighted color when favorited */
    transform: scale(1.2); /* Slightly enlarge when favorited */
}

.favorite-btn:hover {
    color: #ffdf42;
    transform: scale(1.2);
}


.pdf-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    padding: 10px;
    margin: 0 auto; /* Ensure the container is centered */
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}


.react-pdf__Page {
    width: 100%; /* Ensure it does not exceed the parent width */
    max-width: 100%; /* Prevent overflow */
    margin: 20px 0; /* Center vertically with spacing */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.page-container {
    position: relative; /* Ensure child elements align relative to the container */
    height: auto; /* Let it expand based on content */
    margin-bottom: 20px; /* Add spacing between rows */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center contents horizontally */
    overflow: visible; /* Ensure children are visible */
}

.page-content {
    /* padding: 15px; */
    margin: auto;
    /* background-color: #fff; */
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.page-number {
    display: inline-block; /* Inline styling with controlled width/height */
    font-size: 14px; /* Slightly smaller font size for elegance */
    font-weight: bold;
    color: white; /* Contrasting text color */
    background: linear-gradient(45deg, #98FF98, #76E276);
    /* background-color: #98FF98; A pleasant blue tone */
    padding: 5px 10px; /* Padding for better readability */
    border-radius: 20px; /* Fully rounded for pill-like appearance */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    margin-top: 0%; /* Space from the page */
    text-align: center; /* Center the text */
    z-index: 1; /* Ensure it appears above other elements */
    font-family: 'Arial', sans-serif; /* Use a clean and modern font */
    transition: background-color 0.3s ease; /* Smooth hover effect */
}

/* Hover effect for interactivity */
.page-number:hover {
    background-color: #0056b3; /* Darker blue on hover */
    cursor: default; /* Indicate it's not interactive */
}

.page-separator {
    width: 50px;
    height: 6px;
    background-color: #ccc;
    border-radius: 3px;
    margin: 10px 0; /* Space around the separator */
    z-index: 1;
}

.react-pdf__Page {
    width: 100%; /* Ensure it spans the full width of its container */
    max-width: 600px; /* Prevent it from exceeding the container */
    margin: 10px auto; /* Add spacing and center horizontally */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Relative positioning for child alignment */
}


.react-pdf__Page__textContent.textLayer {
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(var(--scale-factor)) translate(-50%, -50%);
    transform-origin: top left;
    pointer-events: none; /* Prevent interaction with text content */
}

.react-pdf__Page__annotations.annotationLayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Ensure annotations are non-interactive */
}

.react-pdf__Page__canvas {
    display: block; /* Ensure it doesn't collapse */
    user-select: none; /* Prevent text selection on the canvas */
    width: 100%; /* Make it take the full width of the parent container */
    height: auto; /* Maintain the aspect ratio */
    border-radius: 8px; /* Match parent rounding if needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow if parent has one */
    background-color: white; /* Optional: Ensure the background matches */
}



/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .pdf-container {
        max-width: 90vw;
        padding: 5px;
    }

    .react-pdf__Page {
        width: 100% !important;
    }

    .page-number {
        font-size: 12px;
        padding: 3px 8px;
    }

    select {
        font-size: 14px;
        padding: 8px 12px;
    }

    .menu {
        width: 90%;
    }
}
